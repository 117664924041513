.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container {
  height: 100vh;
}

.flex-fill {
  flex: 1;
}

.navContainer {
  background-color: #438d68;
  width: 10%;
  height: 100%;
  color: #ffffff;
}

.nav-bar {
  align-items: flex-start;
  justify-content: flex-start;
}

.nav-element {
  color: #ffffff;
  font-size: 24px;
}

.list-view {
  background-color: #ffffff;
  width: 100%;
}

.dropContainer {
  border-radius: 10px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  height: 40%;
  width: 100%;
  display: grid;
  align-items: center;
}

.dropzone {
  padding: 50px;
  background-color: #f2f2f2;
  text-align: center;
  cursor: pointer;
  height: 100%;
}

.drag-active {
  background-color: #e2e3e5;
}

.addContainer {
  width: 80%;
}

.tableHeader {
  background-color: rgb(67, 141, 104, 0.2);
  color: #000000;
}

.tableStyle {
  background-color: white;
  font-size: 18;
  font-weight: 400;
  padding: 10;
  color: #000000;
  border-width: 0;
}

.submitContainer {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  /* margin-block-end: 8px; */
}

.exportContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8;
  margin-block-end: 8px;
}

.exportBtn {
  background-color: #438d68;
  color: #ffffff;
  border-radius: 10;
  font-size: 10;
  margin: 10;
}
.hide-resize-observer {
  display: none !important;
}

.uploadFileDiv {
  border: 2px dashed #438d68;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dragActive {
  background-color: rgba(67, 141, 104, 0.2);
}

.cancleBtn {
  background-color: #d11a2a;
  color: #d11a2a;
  border-radius: 10;
  font-size: 10;
  margin: 10;
}
